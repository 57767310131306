const HTTP_PROTOCOL = "http:";
const HTTPS_PROTOCOL = "https:";
const MAILTO_PROTOCOL = "mailto:";
/**
 * Returns true if the given URL is external to the current location, unless it's a mailto: URL.
 *
 * All links with different protocols, hosts, subdomains, or ports are considered external.
 *
 * If {@link href} is empty, return false;
 *
 * @param href The URL to check.
 * @param currentLocation The current location to use as the base for the URL. Defaults to
 * `location`. This parameter generally does not need to be provided, but can be useful for testing.
 */
export function showExternalLinkIcon(
    href?: string,
    currentLocation: Location | URL = location,
): boolean {
    if (!href) {
        return false;
    }

    // Construct a URL object from the given href, using the current location as the base.
    const url = new URL(href, currentLocation.href);
    if (
        (url.protocol === HTTP_PROTOCOL || url.protocol === HTTPS_PROTOCOL)
        && (currentLocation.protocol === HTTP_PROTOCOL
            || currentLocation.protocol === HTTPS_PROTOCOL)
    ) {
        // For http and https links, make an exception for http/https links within the same host.
        // Ideally, we should never link to http from https, but in the case that we do, we should
        // treat it as an internal link when the host is the same.
        return url.host !== currentLocation.host;
    } else if (url.protocol === MAILTO_PROTOCOL) {
        return false;
    } else {
        return url.protocol !== currentLocation.protocol || url.origin !== currentLocation.origin;
    }
}
