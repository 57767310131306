import clsx from "clsx";
import { containsIgnoreCase, Str } from "core";
import { MarkedText } from "components/MarkedText";
import { DropdownMenu, InlineDropdownMenu } from "components/Menu";
import { PrevNext, PrevNextProps } from "components/PrevNext/PrevNext";
import { Heading, HeadingElement, HeadingMargin, HeadingVariant } from "components/Text";
import { Memo } from "hooks/useBranded";
import React, { Dispatch, ReactNode, SetStateAction, useMemo } from "react";
import "./PrevNext.scss";

const DEFAULT_ITEM_TO_STRING = <T,>(item?: T) => Str.toString(item);

export interface PrevNextSelectorProps<T>
    extends Pick<PrevNextProps, "prevAriaLabel" | "nextAriaLabel" | "prevTooltip" | "nextTooltip"> {
    /**
     * An optional class name to apply to the component.
     */
    className?: string;
    /**
     * The label to apply to the dropdown. This label exists for accessibility and will be
     * visually hidden.
     */
    dropdownLabel: string;
    /**
     * All items that a user can select, sorted in the order that they should appear in
     * the dropdown.
     */
    items: Memo<T[]>;
    /**
     * The currently selected item.
     */
    current: T;
    /**
     * State setting function called when a user selects a new item.
     */
    setCurrent: Dispatch<SetStateAction<T>> | Memo<(value: T) => void>;
    /**
     * A function that given an item, returns the display string to be used in the dropdown
     * and heading. Each item must have a unique display.
     *
     * Default {@link DEFAULT_ITEM_TO_STRING}.
     */
    itemToString?: Memo<(item?: T) => string>;
    /**
     * The heading element to use. Default "h2".
     */
    headingElement?: HeadingElement;
    /**
     * Whether the prev/next buttons should navigate in reverse order of the dropdown.
     * For example, the "next" button would navigate to the item above the current item in the
     * dropdown, and the "prev" button would navigate to the item below. This is useful when
     * working with dates, where you want the dropdown items to be sorted by most recent.
     *
     * Default false.
     */
    reverseOrder?: boolean;
    /**
     * Whether to allow clicking the "next" button while the last item is selected to wrap
     * around to the first item (and likewise for wrapping from the first to last item).
     *
     * Default false.
     */
    wrap?: boolean;
}

/**
 * A component that displays a set of {@link PrevNext} buttons alongside an inline dropdown
 * heading for switching between different items.
 *
 * Note: This is a v0 component that has not been fully defined by design. It will likely be
 * updated in the future when it is fully defined.
 */
export function PrevNextSelector<T>({
    className,
    dropdownLabel,
    items,
    current,
    setCurrent,
    itemToString = DEFAULT_ITEM_TO_STRING as Memo<(item?: T) => string>,
    headingElement = "h2",
    reverseOrder = false,
    wrap = false,
    ...prevNextProps
}: PrevNextSelectorProps<T>): ReactNode {
    const { dropdownProps, onItemClick, filterText } = InlineDropdownMenu.useMandatory({
        value: current,
        setValue: setCurrent,
        valueToString: itemToString,
        initialDisplay: itemToString(current),
    });
    const currentIndex = items.findIndex((item) => itemToString(item) === itemToString(current));
    const filteredItems = useMemo(
        () => items.filter((item) => containsIgnoreCase(itemToString(item), filterText || "")),
        [filterText, itemToString, items],
    );
    return (
        <div className={clsx(className, "bb-prev-next-selector")}>
            <PrevNext
                {...prevNextProps}
                onMove={(direction) => {
                    direction = direction * (reverseOrder ? -1 : 1);
                    const newIndex = (currentIndex + direction + items.length) % items.length;
                    // Avoid updating current selection to undefined
                    if (items[newIndex] !== undefined) {
                        setCurrent(items[newIndex]);
                    }
                }}
                prevDisabled={
                    !items.length
                    || (!wrap
                        && itemToString(current)
                            === itemToString(items[reverseOrder ? items.length - 1 : 0]))
                }
                nextDisabled={
                    !items.length
                    || (!wrap
                        && itemToString(current)
                            === itemToString(items[reverseOrder ? 0 : items.length - 1]))
                }
            />
            <InlineDropdownMenu
                {...dropdownProps}
                label={dropdownLabel}
                arrowLabel={"Press enter to change"}
                textElement={
                    <Heading
                        element={headingElement}
                        variant={HeadingVariant.MEDIUM}
                        marginType={HeadingMargin.NONE}
                    >
                        {dropdownProps.value}
                    </Heading>
                }
                stickyHeader={
                    !filteredItems.length ? (
                        <DropdownMenu.Supplement>No results</DropdownMenu.Supplement>
                    ) : undefined
                }
            >
                {filteredItems.length ? (
                    <DropdownMenu.Section>
                        {filteredItems.map((item) => {
                            return (
                                <DropdownMenu.Option
                                    label={
                                        <MarkedText textToMark={filterText}>
                                            {itemToString(item)}
                                        </MarkedText>
                                    }
                                    onClick={() => {
                                        onItemClick(item);
                                    }}
                                    selected={itemToString(item) === itemToString(current)}
                                    key={itemToString(item)}
                                />
                            );
                        })}
                    </DropdownMenu.Section>
                ) : null}
            </InlineDropdownMenu>
        </div>
    );
}
