import clsx from "clsx";
import { IconButton } from "components/Button";
import * as Icon from "components/Icon";
import { H2, HeadingMargin } from "components/Text";
import { useEllipsisTooltip } from "components/Tooltip";
import { Expandable, ExpandableOrientation, ExpandableProps } from "components/util/Expandable";
import { everIdProp } from "EverAttribute/EverId";
import { Memo } from "hooks/useBranded";
import { useDetectClickOutside } from "hooks/useDetectClickOutside";
import { useFocusTrap } from "hooks/useFocusTrap";
import { ResizableSide, useResizer } from "hooks/useResizer";
import { useReturnFocus } from "hooks/useReturnFocus";
import React, {
    CSSProperties,
    Dispatch,
    FC,
    ReactNode,
    useCallback,
    useEffect,
    useId,
    useRef,
} from "react";
import { combineSelectors } from "util/css";
import "./SideDrawer.scss";
import { EverIdProp } from "util/type";

export const SIDE_DRAWER_DEFAULT_TRANSITION_TIME = 300;

export enum SideDrawerSide {
    LEFT = "left",
    RIGHT = "right",
}

export interface SideDrawerProps
    extends Pick<ExpandableProps, "id" | "className" | "expanded" | "onTransitionComplete">,
        EverIdProp {
    /**
     * An optional className to apply to the content div of the side drawer, which contains all
     * the content (header, body, and footer).
     */
    contentClassName?: string;
    /**
     * The contents to be placed inside the body div of the side drawer, between the header
     * and footer.
     */
    children: ReactNode;
    /**
     * The heading content to display on the left-hand side of the header. If provided a
     * string, the heading text will automatically be formatted as a heading with ellipsing
     * functionality.
     */
    heading: ReactNode;
    /**
     * The heading content to display on the right-hand side of the header, to the left of
     * the close button.
     */
    headerRight?: ReactNode;
    /**
     * The content to display in the footer. If not provided, the footer will not
     * be displayed.
     */
    footer?: ReactNode;
    /**
     * An optional time value in milliseconds indicating the duration of the animation.
     * Default 300ms.
     */
    transitionTime?: number;
    /**
     * The side of the viewport that the drawer should slide in from.
     */
    side: SideDrawerSide;
    /**
     * The "position" CSS value to use for the side drawer.
     *
     * Use "fixed" if it is possible for the page to scroll. This positions the side drawer
     * relative to the viewport, so you will likely need to use {@link spaceAbove} to ensure
     * that it appears under any page header rather than partially covering it.
     *
     * Only use "absolute" if you are fairly certain that scrolling won't be an issue. This
     * positions the side drawer relative to the nearest positioned ancestor, which can be
     * useful depending on where you want it to appear. However, if the main page content is
     * scrollable, the side drawer may move as the page is scrolled. If scrolling is an issue,
     * use "fixed" instead.
     *
     * Default "fixed".
     */
    position?: "fixed" | "absolute";
    /**
     * The width in pixels of the fully expanded side drawer.
     *
     * If {@link resizeable} is true, then this must be a state variable, and {@link setWidth}
     * must be provided for resizing to work properly.
     */
    width: number;
    /**
     * A setter for the width state variable. Only applicable when {@link resizeable} is true.
     */
    setWidth?: Memo<Dispatch<number>>;
    /**
     * The min width of the side panel in pixels. Generally, you should not use values smaller
     * than 400, except in rare cases where this is necessary. Default 400.
     *
     * Only applicable when {@link resizeable} is true.
     */
    minWidth?: number;
    /**
     * The max width of the side panel in pixels.
     *
     * Only applicable when {@link resizeable} is true.
     */
    maxWidth?: number;
    /**
     * Whether the side drawer should be resizeable, within the bounds of {@link minWidth}
     * and {@link maxWidth}. Default false.
     */
    resizeable?: boolean;
    /**
     * The amount of space in pixels to reserve above the side drawer (e.g. for a header bar).
     */
    spaceAbove?: number;
    /**
     * The amount of space in pixels to reserve above the side drawer (e.g. for a footer bar).
     */
    spaceBelow?: number;
    /**
     * The function to call when the user closes the drawer by clicking the "X" button or
     * clicking outside the drawer.
     */
    onClose: () => void;
    /**
     * The function to call when the user clicks outside the side drawer.
     * This function must be memoized.
     */
    onClickOutside?: Memo<EventListener>;
    /**
     * Selectors for elements that should be ignored when detecting outside clicks. Children
     * of given selectors will be included automatically.
     *
     * Only applicable when {@link onClickOutside} is provided.
     */
    clickOutsideExcludedSelectors?: string[];
    /**
     * Whether the side drawer should behave as a modal. If true, focus will be trapped within
     * the side drawer and hitting the Esc key will close it. Default true.
     */
    modal?: boolean;
}

/**
 * A simple side drawer that slides in from one side of the viewport and takes up the entire
 * length of the entry side.
 */
export const SideDrawer: FC<SideDrawerProps> & { Paginator: FC<PaginatorProps> } = ({
    id,
    everId,
    className,
    children,
    contentClassName,
    expanded,
    transitionTime = SIDE_DRAWER_DEFAULT_TRANSITION_TIME,
    side,
    position = "fixed",
    width,
    setWidth,
    resizeable = false,
    minWidth = 400,
    maxWidth,
    spaceAbove = 0,
    spaceBelow = 0,
    heading,
    headerRight,
    footer,
    modal = true,
    onClickOutside,
    clickOutsideExcludedSelectors = [],
    onClose,
    onTransitionComplete,
}: SideDrawerProps) => {
    const drawerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    useFocusTrap(contentRef, expanded && modal);
    useReturnFocus(expanded && modal);
    useEffect(() => {
        modal && expanded && contentRef.current?.focus();
    }, [expanded, modal]);

    const { resizeableElementRef, resizerOnMouseDown } = useResizer<HTMLDivElement>({
        externalResizeableRef: drawerRef,
        minWidth,
        maxWidth,
        setWidth,
        resizableSide: side === SideDrawerSide.LEFT ? ResizableSide.RIGHT : ResizableSide.LEFT,
    });

    const clickHandler = useCallback(
        (e: Event) => {
            if (!onClickOutside || !expanded) {
                return;
            }
            // Ignore click events on the target. The target element should handle its own events.
            const excludedSelector = combineSelectors(clickOutsideExcludedSelectors);
            if (
                excludedSelector
                && e instanceof MouseEvent
                && e.target instanceof Element
                && e.target.matches(excludedSelector)
            ) {
                return;
            }
            onClickOutside(e);
        },
        // We want to be able to pass inline arrays of selectors. Without joining the array
        // this would recompute on every render.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [clickOutsideExcludedSelectors?.join(), expanded, onClickOutside],
    );
    useDetectClickOutside(drawerRef, clickHandler, { useMouseDown: true });

    const headingId = useId();
    const { tooltipComponent: headingTooltip, tooltipTargetProps } = useEllipsisTooltip({
        targetClassName: "bb-side-drawer__heading",
        children: heading,
        "aria-hidden": true,
    });

    return (
        <Expandable
            id={id}
            ref={resizeableElementRef}
            className={clsx(
                className,
                "bb-side-drawer",
                `bb-side-drawer--${side}`,
                `bb-side-drawer--${position}`,
            )}
            expanded={expanded}
            orientation={ExpandableOrientation.HORIZONTAL}
            transitionTime={transitionTime}
            onTransitionComplete={onTransitionComplete}
            aria-labelledby={headingId}
            aria-modal={modal}
            role={"dialog"}
            style={
                {
                    "--bb-sideDrawer-spaceAbove": spaceAbove + "px",
                    "--bb-sideDrawer-spaceBelow": spaceBelow + "px",
                } as CSSProperties
            }
        >
            {resizeable && (
                <div
                    className={clsx("bb-side-drawer__resizer", `bb-side-drawer__resizer--${side}`)}
                    onMouseDown={resizerOnMouseDown}
                />
            )}
            <div
                ref={contentRef}
                className={clsx("bb-side-drawer__content", contentClassName)}
                style={
                    {
                        "--bb-sideDrawer-width": width + "px",
                        "--bb-sideDrawer-minWidth": minWidth + "px",
                        "--bb-sideDrawer-maxWidth": maxWidth ? maxWidth + "px" : undefined,
                    } as CSSProperties
                }
                tabIndex={modal ? 0 : undefined}
                {...everIdProp(everId)}
            >
                <div className={"bb-side-drawer__header"}>
                    <div id={headingId} className={"bb-side-drawer__header-left"}>
                        {typeof heading === "string" ? (
                            <>
                                <H2.Medium {...tooltipTargetProps} marginType={HeadingMargin.NONE}>
                                    {heading}
                                </H2.Medium>
                                {headingTooltip}
                            </>
                        ) : (
                            heading
                        )}
                    </div>
                    <div className={"bb-side-drawer__header-right"}>
                        {headerRight}
                        <IconButton aria-label={"Close"} onClick={() => onClose()}>
                            <Icon.X />
                        </IconButton>
                    </div>
                </div>
                <div className={"bb-side-drawer__body"}>{children}</div>
                {footer && (
                    <>
                        <hr className={"bb-side-drawer__divider"} />
                        <div className={"bb-side-drawer__footer"}>{footer}</div>
                    </>
                )}
            </div>
        </Expandable>
    );
};

interface PaginatorProps {
    /**
     * The total number of pages.
     */
    totalPages: number;
    /**
     * The current page, using 1-based indexing.
     */
    currentPage: number;
    /**
     * The function that is called when the previous button is clicked.
     */
    onPreviousClick: () => void;
    /**
     * The function that is called when the next button is clicked.
     */
    onNextClick: () => void;
}

function Paginator({ totalPages, currentPage, onPreviousClick, onNextClick }: PaginatorProps) {
    return (
        <div className={"bb-side-drawer__paginator"}>
            {`${currentPage} of ${totalPages}`}
            <IconButton
                aria-label={"Previous page"}
                disabled={currentPage <= 1}
                onClick={onPreviousClick}
            >
                <Icon.ChevronLeft size={20} />
            </IconButton>
            <IconButton
                aria-label={"Next page"}
                disabled={currentPage === totalPages}
                onClick={onNextClick}
            >
                <Icon.ChevronRight size={20} />
            </IconButton>
        </div>
    );
}

SideDrawer.Paginator = Paginator;
