import { ReactNode, useMemo } from "react";
import { createPortal } from "react-dom";

const DEFAULT_PORTAL_CONTAINER_ID = "bb-portal-roots";

function getDefaultPortalDomNode(): HTMLElement {
    let result: HTMLElement | null = document.getElementById(DEFAULT_PORTAL_CONTAINER_ID);
    if (!result) {
        result = document.createElement("div");
        result.id = DEFAULT_PORTAL_CONTAINER_ID;
        result.className = "react-root";
        document.body.appendChild(result);
    }
    return result;
}

export interface PortalProps {
    /**
     * Element to render {@link children} into. This element should not be `document.body`, as
     * appending children to `document.body` is an expensive operation that can trigger a reflow on
     * the whole page. If not provided, a default element will be used.
     */
    domNode?: Element;
    /**
     * Component to render into the Portal.
     */
    children: ReactNode;
}

/**
 * Simple utility for using React Portals. Use this to render children into a DOM node that exists
 * outside the DOM hierarchy of the parent component.
 */
export function Portal({ domNode, children }: PortalProps): ReactNode {
    const DEFAULT_DOM_NODE = useMemo(() => getDefaultPortalDomNode(), []);
    return createPortal(children, domNode ?? DEFAULT_DOM_NODE);
}
