import { IconButton } from "components/Button";
import { BasicChip } from "components/Chip";
import { Counter, CounterProps } from "components/Counter";
import * as Icon from "components/Icon";
import { ListBoxOption, OptionId, SharedListBoxProps } from "components/Menu/ListBox/BaseListBox";
import { getIdToOptionMap } from "components/Menu/ListBox/ListBoxUtil";
import { Scrollbar } from "components/Scrollbar";
import { Span } from "components/Text";
import { Tooltip } from "components/Tooltip";
import CSS from "csstype";
import { Memo } from "hooks/useBranded";
import { useCombinedRef } from "hooks/useCombinedRef";
import { useResizeObserver } from "hooks/useResizeObserver";
import React, { useId, useMemo, useRef } from "react";

// Things can start to get slow at around 40 chips due to rendering the "Remove" tooltip on
// each chip. 40 was somewhat arbitrarily chosen based on observation, and may need adjustment
// depending on what users are experiencing.
const MAX_SUMMARY_CHIPS = 40;

export interface SelectionSummaryProps<T extends OptionId = OptionId>
    extends Pick<SharedListBoxProps<T>, "items">,
        Pick<CounterProps, "hasWhiteBackground"> {
    /**
     * The heading to display above the listbox summary.
     */
    heading: string;
    /**
     * The set of selected options. Note that the insertion order of this set should reflect
     * the order in which the options were selected by the user, as summary chips will be
     * displayed in the insertion order.
     */
    selected: Memo<Set<T>>;
    /**
     * The function to call when a chip in the summary corresponding to a selected
     * option is removed.
     */
    onRemoveChip: Memo<(option: T) => void>;
    /**
     * An optional function which given the option id, returns the name to display in the chip.
     * If not specified, the {@link ListBoxOption#name} will be used.
     */
    getOptionDisplay?: Memo<(option: T) => string>;
    /**
     * The max height of the summary.
     */
    maxHeight?: CSS.Property.MaxHeight;
}

export function SelectionSummary<T extends OptionId = OptionId>({
    items,
    heading,
    selected,
    onRemoveChip,
    getOptionDisplay,
    maxHeight,
    hasWhiteBackground = true,
}: SelectionSummaryProps<T>) {
    const [resizeRef, resizeEntry] = useResizeObserver<HTMLElement>();
    const headingElement = resizeEntry.target;
    const headingEllipsed =
        headingElement && headingElement.scrollWidth > headingElement.clientWidth;
    const tooltipTargetRef = useRef<HTMLDivElement>(null);
    const headingRef = useCombinedRef(resizeRef, tooltipTargetRef);
    const headingId = useId();
    const idToItem = useMemo(() => {
        return getIdToOptionMap(items);
    }, [items]);

    const chips = useMemo(() => {
        return Array.from(selected)
            .slice(0, MAX_SUMMARY_CHIPS)
            .map((optionId) => {
                const option = idToItem.get(optionId);
                return option ? (
                    <SummaryChip
                        key={optionId}
                        option={option}
                        display={
                            getOptionDisplay
                                ? getOptionDisplay(optionId)
                                : option.name || "[Unknown]"
                        }
                        onRemove={onRemoveChip}
                    />
                ) : null;
            });
    }, [getOptionDisplay, idToItem, onRemoveChip, selected]);

    return (
        <div className={"bb-selection-summary"} role={"list"} aria-labelledby={headingId}>
            <div id={headingId} className={"bb-selection-summary__heading"}>
                <Span.Semibold ref={headingRef} className={"bb-selection-summary__heading-text"}>
                    {heading}
                </Span.Semibold>
                {headingEllipsed && (
                    <Tooltip target={tooltipTargetRef} aria-hidden={true}>
                        {heading}
                    </Tooltip>
                )}
                <Counter hasWhiteBackground={hasWhiteBackground}>{selected.size}</Counter>
            </div>
            <Scrollbar
                autoHeight={true}
                autoHeightMax={maxHeight}
                hideTracksWhenNotNeeded={true}
                thumbSize={60}
            >
                <div className={"bb-selection-summary__chip-container"}>
                    {chips}
                    {selected.size > MAX_SUMMARY_CHIPS && (
                        <Span className={"bb-selection-summary__more-chips-indicator"}>
                            (And {selected.size - MAX_SUMMARY_CHIPS} more)
                        </Span>
                    )}
                    {!selected.size && (
                        <Span className={"bb-text--color-secondary"}>No selections made yet</Span>
                    )}
                </div>
            </Scrollbar>
        </div>
    );
}

interface SummaryChipProps<T extends OptionId> {
    option: ListBoxOption<T>;
    onRemove: (option: T) => void;
    display: string;
}

function SummaryChip<T extends OptionId>({ option, onRemove, display }: SummaryChipProps<T>) {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const removeButtonTooltipId = useId();
    return (
        <>
            <BasicChip
                key={option.id}
                role={"listitem"}
                rightButton={
                    <IconButton
                        ref={buttonRef}
                        aria-labelledby={removeButtonTooltipId}
                        onClick={() => onRemove(option.id)}
                        disabled={option.disabled}
                    >
                        <Icon.X />
                    </IconButton>
                }
            >
                {display}
            </BasicChip>
            <Tooltip key={option.id + "_tooltip"} id={removeButtonTooltipId} target={buttonRef}>
                {(option.disabled && option.disabledChipTooltipContent) || "Remove"}
            </Tooltip>
        </>
    );
}
