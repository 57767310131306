import clsx from "clsx";
import React, { CSSProperties, ReactElement } from "react";
import { EverColor } from "tokens/typescript/EverColor";
import * as ColorTokens from "tokens/typescript/ColorTokens";
import * as DividerTokens from "tokens/typescript/DividerTokens";
import * as CSS from "csstype";
import "./Divider.scss";

export interface DividerProps {
    color?: EverColor;
    height?: CSS.Property.Height;
    width?: CSS.Property.Width;
    className?: string;
}

interface DividerCSSProperties extends CSSProperties {
    "--bb-divider-color": string;
    "--bb-divider-height": CSS.Property.Height;
    "--bb-divider-width": CSS.Property.Width;
}

export function Divider({
    color = ColorTokens.DIVIDER_DEFAULT,
    height = DividerTokens.HEIGHT_DEFAULT,
    width = DividerTokens.WIDTH_DEFAULT,
    className,
}: DividerProps): ReactElement<DividerProps> {
    const style: DividerCSSProperties = {
        "--bb-divider-color": color,
        "--bb-divider-height": height,
        "--bb-divider-width": width,
    };
    return <div role={"separator"} className={clsx("bb-divider", className)} style={style} />;
}
Divider.displayName = "Divider";
